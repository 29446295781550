<div class="header-container">
  <div class="header">
    <div class="header__left">
      <img src="{{ logo }}" alt="Logo" class="header__logo" />
      <img
        src="{{ logo_mobile }}"
        alt="Logo Mobile"
        class="header__logo mobile"
      />
      <ul class="header__left--items">
        <li><a>Booking</a></li>
        <li *ngIf="clinicName">{{ clinicName }}</li>
      </ul>
    </div>
    <div class="header__right" *ngIf="is_logged_in">
      <button class="bttn bttn-outline logout-desktop" (click)="logout()">
        <img src="../../../assets/icons/logout.svg" alt="Log out" /> Log ud
      </button>
      <img
        src="../../../assets/icons/burgermenu.svg"
        alt="Bars"
        class="nav-bars"
        (click)="toggleMobNav()"
      />
    </div>
  </div>
</div>

<div class="mob-nav" *ngIf="mobNav == true && is_logged_in">
  <div class="mob-nav__header mob-padding">
    <div class="mob-nav__header--left">
      <img src="{{ logo }}" alt="Logo" class="mob-nav__header--logo" />
    </div>
    <div class="mob-nav__header--right">
      <img
        src="../../../assets/icons/burgermenu.svg"
        alt="Bars"
        class="nav-bars"
        (click)="toggleMobNav()"
      />
    </div>
  </div>

  <div class="mob-nav__navigation"></div>

  <div class="mob-nav__content mob-padding">
    <div class="mob-nav__content--top" *ngIf="is_logged_in">
      <ul>
        <li (click)="bookNew()">
          <img src="../../../assets/icons/book.svg" alt="Book" />
          <p>Book tid</p>
        </li>
        <li (click)="myBookings()">
          <img src="../../../assets/icons/calendar.svg" alt="Bookings" />
          <p>Mine bookinger</p>
        </li>
        <li (click)="toProfile()">
          <img src="../../../assets/icons/user.svg" alt="Profile" />
          <p>Mine oplysninger</p>
        </li>
      </ul>
    </div>
    <div class="mob-nav__content--bottom" *ngIf="is_logged_in">
      <button class="bttn bttn-outline" (click)="logout()">
        <img alt="Log out" src="../../../assets/icons/logout.svg" />
        Log ud
      </button>
    </div>
  </div>
</div>
