<div id="loading" *ngIf="!formLoaded" class="signup-loading">
  <div class="app-loading map-loading">
    <div class="logo"></div>
    <svg class="spinner" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</div>

<div class="profile mob-padding" *ngIf="formLoaded">
  <h3 class="section-title">Opret</h3>
  <app-info-messages
    #infoMessage
    [notification]="notification"
  ></app-info-messages>
  <div class="profile__container">
    <form id="signup-form" #signupForm="ngForm" autocomplete="off">
      <div class="">
        <div class="signup-form-wrapper profile__container--items">
          <ng-container *ngFor="let formElement of formElements; let i = index">
            <div
              class="signup-input"
              [ngClass]="{
                'signup-input-dropdown': formElement.controlType == 'dropdown',
                hidden:
                  formElement.key === 'username' ||
                  (aalborgFlow &&
                    (formElement.key === 'clinicId' ||
                      formElement.key === 'danmNr' ||
                      formElement.key === 'insuranceCompany'))
                  || formElement.key === 'insuranceCompany'
              }"
              [ngSwitch]="formElement.controlType"
              [ngStyle]="{ 'z-index': formElements.length - i }"
            >
              <label
                class="form-label"
                [ngClass]="{ required: formElement.required }"
                >{{ formElement.label }}</label
              >
              <span *ngSwitchCase="'dropdown'">
                <span
                  class="ui primary dropdown button form-control select-dropdown"
                  suiDropdown
                >
                  <span
                    class="text select-dropdown__text"
                    [id]="formElement.key"
                    required
                  >
                    <ng-container *ngFor="let selected of dropdownSelection">
                      <ng-container *ngIf="selected.key == formElement.key">
                        {{ selected.value }}
                      </ng-container>
                    </ng-container>
                    <span *ngIf="helperDrpdwKeys.indexOf(formElement.key) == -1"
                      >Vælg venligst</span
                    >
                  </span>
                  <i class="dropdown icon"></i>
                  <span class="adevo-menu" class="menu" suiDropdownMenu>
                    <ng-container *ngFor="let value of formElement.vals">
                      <span
                        class="item"
                        (click)="
                          setDropdownSelection($event, formElement, value)
                        "
                        >{{ value.value }}</span
                      >
                    </ng-container>
                  </span>
                </span>
              </span>

              <span class="input-container" *ngSwitchDefault>
                <input
                  [type]="formElement.controlType"
                  [ngClass]="{
                    disabled: clinics && formElement.key === 'clinicId'
                  }"
                  class="form-control input-text"
                  [id]="formElement.key"
                  [name]="formElement.key"
                  [required]="formElement.required"
                  (blur)="
                    checkInputFilled(
                      $event,
                      signupForm.controls,
                      formElement.key,
                      formElement
                    )
                  "
                  [(ngModel)]="formElement.value"
                  [pattern]="formElement.pattern"
                  [customValidator]="formElement"
                  (keyup)="onKey($event, formElement.key)"
                />
              </span>

              <span
                class="error-message"
                [ngClass]="
                  signupForm.controls[formElement.key]?.errors
                    ? 'INVALID'
                    : 'VALID'
                "
                *ngIf="formElement.key !== 'cprNr'"
              >
                <span
                  *ngIf="
                    signupForm.controls[formElement.key]?.errors?.required &&
                    signupForm.controls[formElement.key]?.touched
                  "
                  >{{ formElement.errors.pattern }}</span
                >
                <span
                  *ngIf="signupForm.controls[formElement.key]?.errors?.pattern"
                  >{{ formElement.errors.pattern }}</span
                >
                <span
                  *ngIf="signupForm.controls[formElement.key]?.errors?.custom"
                  >{{ formElement.errors.custom }}</span
                >
              </span>

              <span
                class="error-message"
                [ngClass]="
                  correctCpr || correctCpr === undefined ? 'hidden' : 'valid'
                "
                *ngIf="formElement.key === 'cprNr'"
              >
                <span>Ugyldigt CPR-nr.</span>
              </span>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="div">
        <div class="options-container form-block">
          <div class="checkbox-left checkbox-block">
            <div class="checkbox-wrapper">
              <input id="checkbox-option-1" type="checkbox" />
              <label for="checkbox-option-1"></label>
              <span
                >Jeg ønsker IKKE at modtage email påmindelser til
                behandlinger</span
              >
            </div>
            <div class="checkbox-wrapper">
              <input id="checkbox-option-2" type="checkbox" />
              <label for="checkbox-option-2"></label>
              <span>Jeg ønsker IKKE at modtage email påmindelser til hold</span>
            </div>
          </div>

          <div class="checkbox-right checkbox-block">
            <div class="checkbox-wrapper">
              <input id="checkbox-option-3" type="checkbox" />
              <label for="checkbox-option-3"></label>
              <span
                >Jeg ønsker IKKE at modtage SMS påmindelser til
                behandlinger</span
              >
            </div>
            <div class="checkbox-wrapper">
              <input id="checkbox-option-4" type="checkbox" />
              <label for="checkbox-option-4"></label>
              <span>Jeg ønsker IKKE at modtage SMS påmindelser til hold</span>
            </div>
          </div>
        </div>

        <div class="number-validation form-block">
          <span>Venligst indtast nummeret på billedet nedenfor:</span>
          <div class="form-group">
            <div class="validation-wrapper">
              <input
                type="number"
                class="form-control"
                id="validation-number-user"
                required
                name="validation-number"
              />
              <div
                id="validation-generated"
                attr.data-generated="{{ validationNumber }}"
              >
                <span>{{ validationNumber }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="terms-wrapper form-block">
          <div class="terms-container">
            <div class="terms">
              <h2>
                Samtykke til videregivelse af helbredsoplysninger fra klinik
              </h2>
              <p>
                Ved at booke tid til behandling gennem fbooking.dk afgiver du
                hermed samtykke til, at oplysninger om dig videregives til
                FysioDanmark ApS, den enkelte FysioDanmark klinik.
              </p>
              <p>
                Uden dit samtykke må vi ikke videregive dine oplysninger til
                FysioDanmark ApS.
              </p>
              <p>
                Dine personoplysninger bliver behandlet for at kunne give dig
                den hjælp, du har behov for og for at håndtere klagebehandling,
                afregning, opfølgning og kvalitetssikring af behandlingsforløbet
                hos FysioDanmark ApS.
              </p>
              <p>
                Den enkelte FysioDanmark klinik opbevarer oplysningerne i mindst
                5 år efter reglerne i journalføringsbekendtgørelsen.
                FysioDanmark sletter oplysningerne, når samtykket bortfalder
                efter 1 år.
              </p>
              <p>
                Du har til enhver tid ret til at anmode om indsigt i og
                berigtigelse eller sletning af personoplysninger eller
                begrænsning af behandling vedrørende dig eller til at gøre
                indsigelse mod behandling samt retten til dataportabilitet.
              </p>
              <p>
                Du kan til enhver tid trække dit samtykke tilbage.
                Tilbagetrækningen kan have betydning for behandlingen af din
                sag, herunder at du selv skal afgive oplysningerne direkte til
                FysioDanmark.
              </p>
              <p>
                Her er vores kontaktoplysninger, hvis du ønsker at gøre brug af dine rettigheder.
              </p>
              <p>FysioDanmark ApS</p>
              <p>Carl Jacobsens Vej 16<br />2500 Valby</p>
              <p>
                Mail:
                <a href="mailto:fysiodanmark@fysiodanmark.dk"
                  >fysiodanmark@fysiodanmark.dk</a
                >
              </p>
              <p>CVR. 33600402</p>
              <p>
                Endelig kan du gøre dine rettigheder gældende direkte overfor
                den enkelte FysioDanmark klinik hvor du modtager behandling.
              </p>
              <p><b>Jeg samtykker hermed til, at:</b></p>
              <p>
                Den enkelte FysioDanmark klinik må videregive mine
                personoplysninger, herunder helbredsoplysninger og personnummer,
                til FysioDanmark ApS til brug for klagebehandling, opfølgning og
                kvalitetssikring af behandlingsforløbet.
              </p>
              <p>
                FysioDanmark ApS og FysioDanmark klinikken samt disses
                underleverandører må sende mig bookingbekræftelse, adviseringer,
                kvalitetssikring - og tilfredshedsundersøgelse indeholdende fx
                Net Promoter Score pr. e-mail og SMS.
              </p>
              <p>
                Endeligt kan du kan klage over vores behandling af dine
                personoplysninger til Datatilsynet:
              </p>
              <p>
                Borgergade 28, 5.<br />1300 København K<br />
                Tlf. <a href="tel:33193200">33 19 32 00</a><br />
                <a href="mailto:dt@datatilsynet.dk">dt@datatilsynet.dk</a>
              </p>
              <p>
                <a href="https://www.datatilsynet.dk/" target="_blank"
                  >www.datatilsynet.dk</a
                >
              </p>
            </div>
          </div>
          <div class="checkbox-wrapper">
            <input type="checkbox" id="checkbox-terms" name="terms" #terms />
            <label for="checkbox-terms"></label>
            <span>Jeg er enig i FysioDanmarks betingelser og vilkår</span>
          </div>
        </div>

        <div class="button-wrapper form-block">
          <button class="bttn filled" (click)="signupUser()">OPRET</button>
        </div>
      </div>
    </form>
  </div>
</div>
