<div id="loading" *ngIf="loading">
  <div class="app-loading profile-loading">
    <div class="logo"></div>
    <svg class="spinner" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</div>

<div
  id="bookings-container"
  class="bookings main-container mob-padding"
  *ngIf="!loading"
>
  <h3 class="section-title">Mine bookinger</h3>
  <div
    class="bookings__info"
    *ngIf="
      (currentBooking?.length == 0 && bookings?.length !== 0) ||
      bookings?.length == 0
    "
  >
    <div *ngIf="currentBooking?.length == 0 && bookings?.length !== 0">
      <h3 class="mb0">Vælg venligst en booking!</h3>
    </div>
    <div *ngIf="bookings?.length == 0">
      <h3 class="mb0">Ingen booking fundet</h3>
    </div>
  </div>
  <div class="bookings__container">
    <div class="bookings__left" *ngIf="currentPage?.length !== 0">
      <ng-container *ngFor="let booking of currentPage">
        <div
          class="bookings__left--box"
          *ngIf="booking.clinicData && booking.therapistData"
          [ngClass]="{
            selected: selectedBooking == booking.booking,
            pastBooking: !booking.isAllowedDelete
          }"
          (click)="selectBooking(booking)"
        >
          <div class="bookings__left--box__desc">
            <div class="bookings__left--box__header" *ngIf="booking.dateHeader">
              <p>{{ booking.dateHeader }}</p>
            </div>
            <div class="bookings__left--box__clinic">
              <p>{{ booking.clinicData.name }}</p>
            </div>
            <div
              class="bookings__left--box__treatment"
              *ngIf="booking.comment.behandling"
            >
              <p>{{ booking.comment.behandling }}</p>
            </div>
          </div>
          <div class="bookings__left--box__view">
            <img src="../../../assets/icons/arrow-r.svg" alt="" />
          </div>
        </div>
        <div
          class="bookings__left--showDetails"
          *ngIf="
            currentBooking?.length > 0 && showMobBookingDetails == booking._id
          "
        >
          <div class="bookings__left--showDetails--top">
            <h3 class="mb0">Bookinginformationer</h3>
            <div class="bookings__left--showDetails--top__icons">
              <img
                src="../../../assets/icons/print.svg"
                (click)="export(current)"
                alt=""
              />
            </div>
          </div>
          <div
            class="bookings__left--showDetails--content"
            *ngFor="let current of currentBooking"
          >
            <div class="bookings__left--showDetails--content--left">
              <div
                class="bookings__left--showDetails--content--left__box"
                *ngIf="current.clinicData"
              >
                <h6>Klinik</h6>
                <p>
                  {{ current.clinicData.name }}<br />
                  {{ current.clinicData.address }}<br />
                  {{ current.clinicData.post_code }}
                  {{ current.clinicData.city }}
                </p>
              </div>
              <div
                class="bookings__left--showDetails--content--left__box"
                *ngIf="current.comment.behandling"
              >
                <h6>
                  {{
                    type === 'aalborg'
                      ? 'Konsultationstype'
                      : 'Bookinginformationer'
                  }}
                </h6>
                <p>
                  {{
                    type === 'aalborg'
                      ? 'Sundhedsordning Ældre- og Handicapforvaltningen'
                      : current.comment.behandling
                  }}
                </p>
              </div>
              <div
                class="bookings__left--showDetails--content--left__box"
                *ngIf="
                  type !== 'aalborg' &&
                  current.comment.insurance !== undefined &&
                  current.comment.insurance != ''
                "
              >
                <h6>Forsikringsselskab</h6>
                <p>{{ current.comment.insurance }}</p>
              </div>
              <div
                class="bookings__left--showDetails--content--left__box"
                *ngIf="current.comment.bodyparts"
              >
                <h6>Behandling</h6>
                <p>{{ current.comment.bodyparts }}</p>
              </div>
              <div
                class="bookings__left--showDetails--content--left__box"
                *ngIf="current.comment.bodyparts"
              >
                <h6>Fysioterapeut</h6>
                <div
                  class="bookings__left--showDetails--content--left__box--therapist"
                >
                  <div
                    *ngIf="current.therapistData.picture"
                    class="therapist-picture"
                    [ngStyle]="{
                      background:
                        'url(' + current.therapistData.picture + ') #eaeced'
                    }"
                  ></div>
                  <div
                    *ngIf="!current.therapistData.picture"
                    class="therapist-picture"
                    [ngStyle]="{
                      background:
                        'url(../../../assets/images/default-avatar.png) #eaeced'
                    }"
                  ></div>
                  <div>
                    <h6>{{ current.therapistData.name }}</h6>
                    <p>Fysioterapeut</p>
                  </div>
                </div>
              </div>
              <div class="bookings__left--showDetails--content--left__box">
                <h6>Dato og tidspunkt</h6>
                <p>
                  {{ current.date.split(' ')[0] }}.
                  {{ current.date.split(' ')[1].toLowerCase() }}, kl.
                  {{ current.from.substring(11, 16) }} -
                  {{ current.to.substring(11, 16) }}
                </p>
              </div>
            </div>
            <div class="bookings__left--showDetails--bottom">
              <img
                src="../../../assets/icons/delete.svg"
                alt=""
                class="remove-booking"
                (click)="openDeletePopup()"
                *ngIf="showDelete"
              />
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="bookings__right" [class.nobookings]="bookings?.length == 0">
      <div *ngIf="currentBooking?.length == 0 && bookings?.length !== 0">
        <div class="bookings__right--top without-margin">
          <h3 class="mb0">Vælg venligst en booking!</h3>
        </div>
      </div>
      <div *ngIf="bookings?.length == 0">
        <div class="bookings__right--top without-margin">
          <h3 class="mb0">Ingen booking fundet</h3>
        </div>
      </div>
      <div *ngIf="currentBooking?.length > 0">
        <div
          class="bookings__right--container"
          *ngFor="let current of currentBooking"
        >
          <div class="bookings__right--top">
            <h3 class="mb0">Bookinginformationer</h3>
            <div class="bookings__right--top__icons">
              <img
                src="../../../assets/icons/print.svg"
                alt=""
                (click)="export(current)"
              />
              <img
                src="../../../assets/icons/delete.svg"
                alt=""
                class="remove-booking"
                (click)="openDeletePopup()"
                *ngIf="showDelete"
              />
            </div>
          </div>
          <div class="bookings__right--content">
            <div class="bookings__right--content--left">
              <div
                class="bookings__right--content--left__box"
                *ngIf="current.clinicData"
              >
                <h6>Klinik</h6>
                <p>
                  {{ current.clinicData.name }}<br />
                  {{ current.clinicData.address }}<br />
                  {{ current.clinicData.post_code }}
                  {{ current.clinicData.city }}
                </p>
              </div>
              <div
                class="bookings__right--content--left__box"
                *ngIf="current.comment.behandling"
              >
                <h6>
                  {{
                    type === 'aalborg'
                      ? 'Konsultationstype'
                      : 'Bookinginformationer'
                  }}
                </h6>
                <p>
                  {{
                    type === 'aalborg'
                      ? 'Sundhedsordning Ældre- og Handicapforvaltningen'
                      : current.comment.behandling
                  }}
                </p>
              </div>
              <div
                class="bookings__right--content--left__box"
                *ngIf="
                  type !== 'aalborg' &&
                  current.comment.insurance !== undefined &&
                  current.comment.insurance != ''
                "
              >
                <h6>Forsikringsselskab</h6>
                <p>{{ current.comment.insurance }}</p>
              </div>
              <div
                class="bookings__right--content--left__box"
                *ngIf="current.comment.bodyparts"
              >
                <h6>Behandling</h6>
                <p>{{ current.comment.bodyparts }}</p>
              </div>
            </div>
            <div class="bookings__right--content--center">
              <div class="bookings__right--content--left__box">
                <h6>Fysioterapeut</h6>
                <div class="bookings__right--content--left__box--therapist">
                  <div
                    *ngIf="current.therapistData.picture"
                    class="therapist-picture"
                    [ngStyle]="{
                      background:
                        'url(' + current.therapistData.picture + ') #eaeced'
                    }"
                  ></div>
                  <div
                    *ngIf="!current.therapistData.picture"
                    class="therapist-picture"
                    [ngStyle]="{
                      background:
                        'url(../../../assets/images/default-avatar.png) #eaeced'
                    }"
                  ></div>
                  <div>
                    <h6>{{ current.therapistData.name }}</h6>
                    <p>Fysioterapeut</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bookings__right--content--right">
              <div class="bookings__right--content--left__box">
                <h6>Dato og tidspunkt</h6>
                <p>
                  {{ current.date.split(' ')[0] }}.
                  {{ current.date.split(' ')[1].toLowerCase() }}, kl.
                  {{ current.from.substring(11, 16) }} -
                  {{ current.to.substring(11, 16) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="bookings__pagination"
    *ngIf="bookings?.length !== 0 && totalPages > 0"
  >
    <button
      class="pagination-nav left"
      (click)="navigatePage(-1)"
      *ngIf="activePage > 0"
      [ngClass]="{ disabled: activePage === 1 }"
    >
      <img src="{{ arrowLeftIcon }}" alt="" class="arrow-right" />
    </button>
    <span class="bookings__pagination--info"
      >{{ activePage }} / {{ totalPages | number: '1.0-0' }}</span
    >
    <button
      class="pagination-nav right"
      (click)="navigatePage(+1)"
      *ngIf="activePage < totalPages"
      [ngClass]="{ disabled: activePage === totalPages }"
    >
      <img src="{{ arrowRightIcon }}" alt="" class="arrow-right" />
    </button>
  </div>
</div>

<div class="popup-container" *ngIf="show">
  <div class="popup">
    <img src="{{ delete }}" class="close-popup" (click)="closeDeletePopup()" />
    <div class="popup-title">Vil du slette denne reservation?</div>
    <div class="button-container">
      <button
        class="bttn accept-remove"
        (click)="deleteBooking(currentBooking[0])"
      >
        Ja
      </button>
      <button class="bttn filled" (click)="closeDeletePopup()">Nej</button>
    </div>
  </div>
</div>
