<div class="welcome mob-padding">
  <h3 class="section-title">Velkommen til FysioDanmark Booking</h3>
  <div class="welcome__buttons" *ngIf="flowType === 'aalborg'">
    <div class="welcome__buttons--item" id="book-new" (click)="bookNew()">
      <img src="../../../assets/icons/book.svg" alt="Book tid">
      <p>Book Tid</p>
    </div>
    <div class="welcome__buttons--item" (click)="myBookings()">
      <img src="../../../assets/icons/calendar.svg" alt="Mine bookinger">
      <p>Mine bookinger</p>
    </div>
    <div class="welcome__buttons--item" (click)="toProfile()">
      <img src="../../../assets/icons/user.svg" alt="Mine oplysninger">
      <p>Mine oplysninger</p>
    </div>
  </div>
  <div class="welcome__buttons" *ngIf="(!bookings || currentBooking === undefined) && flowType !== 'aalborg'">
    <div class="welcome__buttons--item" id="book-new" (click)="bookNew()">
      <img src="../../../assets/icons/book.svg" alt="Book tid">
      <p>Book Tid</p>
    </div>
    <div class="welcome__buttons--item" (click)="myBookings()">
      <img src="../../../assets/icons/calendar.svg" alt="Mine bookinger">
      <p>Mine bookinger</p>
    </div>
    <div class="welcome__buttons--item" (click)="toProfile()">
      <img src="../../../assets/icons/user.svg" alt="Mine oplysninger">
      <p>Mine oplysninger</p>
    </div>
  </div>
  <div class="welcome__havePrevious" *ngIf="bookings && currentBooking !== undefined && flowType !== 'aalborg'">
    <div class="welcome__havePrevious--box" *ngIf="currentBooking.serviceData">
      <div class="welcome__havePrevious--item" (click)="bookOfferedVisit(currentBooking)">
        <img src="../../../assets/icons/user-circle.svg" alt="Book tid">
        <p>Book en ny tid hos {{ currentBooking.therapistData.name }}</p>
      </div>
      <div class="welcome__havePrevious--item" id="book-new" (click)="bookNew()">
        <img src="../../../assets/icons/book.svg" alt="Book tid">
        <p>{{ bookingText }}</p>
      </div>
    </div>
    <div class="welcome__havePrevious--box">
      <div class="welcome__havePrevious--item" 
           id="book-new" 
           (click)="bookNew()"
           *ngIf="!currentBooking.serviceData">
        <img src="../../../assets/icons/book.svg" alt="Book tid">
        <p>Book Tid</p>
      </div>
      <div class="welcome__havePrevious--item" (click)="myBookings()">
        <img src="../../../assets/icons/calendar.svg" alt="Mine bookinger">
        <p>Mine bookinger</p>
      </div>
      <div class="welcome__havePrevious--item" (click)="toProfile()">
        <img src="../../../assets/icons/user.svg" alt="Mine oplysninger">
        <p>Mine oplysninger</p>
      </div>
    </div>
  </div>
</div>
