<div class="message-container">
  <div
    *ngIf="notification.type !== 'error-with-links'"
    id="notification-message"
    [ngClass]="{
      error: notification.type === 'error',
      success: notification.type === 'success'
    }"
  >
    <span>{{ notification.message }}</span>
  </div>
  <div
    *ngIf="notification.type === 'error-with-links'"
    id="notification-message"
    class="error with-links"
  >
    <span>
      <p>
        FysioDanmark-bookingsystem samarbejder med andre systemer og vi kan se
        at du i et af disse systemer tidligere har registreret en bruger på det
        anvendte CPR-nr.
      </p>
      <p>
        Vælg
        <span class="link" (click)="goToPassword()"
          >'Glemt eller nyt kodeord'</span
        >
        her, eller fra
        <span class="link" (click)="goToLogin()">login-siden</span>, for at
        oprette et nyt kodeord til din eksisterende bruger.
      </p>
    </span>
  </div>
</div>
