<div class="login-container">
  <div class="login-container__top">
    <div id="loading" *ngIf="loading">
      <div class="app-loading map-loading">
        <div class="logo"></div>
        <svg class="spinner" viewBox="25 25 50 50">
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </div>

    <ng-container *ngIf="(token && tokenExpired) || cprLoading; else noToken">
      <h4>{{ tokenText }}</h4>
    </ng-container>

    <ng-template #noToken>
      <div class="login-container__top--form" [hidden]="submitted">
        <h3>Log ind</h3>
        <app-info-messages
          #infoMessage
          [notification]="notification"
        ></app-info-messages>
        <form
          id="login-form"
          #loginForm="ngForm"
          autocomplete="off"
          (ngSubmit)="login()"
        >
          <input
            type="text"
            id="cpr"
            placeholder="CPR (uden bindestreg) / E-mail"
            required
            [(ngModel)]="modelLogin.cprNr"
            name="cpr"
            #cpr="ngModel"
          />
          <input
            type="password"
            id="password"
            placeholder="Kodeord"
            required
            [(ngModel)]="modelLogin.password"
            name="password"
          />
          <button class="bttn bttn__fill" [disabled]="!loginForm.form.valid">
            Log ind
          </button>
          <p class="forgot-password">
            <a (click)="goToForgot()">Glemt eller nyt kodeord</a>
          </p>
        </form>
      </div>
    </ng-template>
  </div>
  <div class="login-container__bottom" *ngIf="!token && !cprLoading">
    <h4>Er du ikke oprettet endnu?</h4>
    <a (click)="goToRegister()">
      <button type="button" name="button-signup" class="bttn signup">
        Opret ny bruger her
      </button>
    </a>
  </div>
</div>
