<div class="login-container">
  <div class="login-container__top">
    <div class="login-container__top--form">
      <h3>Gendan adgangskode</h3>
      <app-info-messages
        #infoMessage
        [notification]="notification"
      ></app-info-messages>
      <form
        id="login-form"
        autocomplete="off"
        #myForm="ngForm"
        (ngSubmit)="sendForgotPassword()"
      >
        <div class="form-group">
          <div class="cpr-input">
            <div class="error-message" *ngIf="!data.cpr.validate">
              <span>Ugyldigt CPR-nr.</span>
            </div>

            <input
              class="form-control"
              id="cpr"
              placeholder="Indtast din CPR-nr."
              name="cpr"
              type="cpr"
              (keyup)="handleInput($event)"
              required
              [(ngModel)]="data.cpr.value"
              #cprField="ngModel"
            />
          </div>

          <div class="recover-type">
            <label class="radio-container"
              >E-mail
              <input
                type="radio"
                name="radio"
                value="email"
                (click)="selectType($event)"
                checked
              />
              <span class="checkmark"></span>
            </label>
            <label class="radio-container"
              >SMS
              <input
                type="radio"
                name="radio"
                value="sms"
                (click)="selectType($event)"
              />
              <span class="checkmark"></span>
            </label>
          </div>

          <div
            class="login-input"
            [ngClass]="{ active: recoverType === 'email' }"
          >
            <div class="error-message" *ngIf="emailField.errors?.pattern">
              <span
                >Email skal udfyldes og skal formateres
                'adresse@domain.xx'!</span
              >
            </div>

            <input
              class="form-control"
              id="email"
              placeholder="Indtast din e-mailadresse"
              name="email"
              type="email"
              (keyup)="handleInput($event)"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              [(ngModel)]="data.email.value"
              #emailField="ngModel"
            />
          </div>

          <div
            class="phone-input"
            [ngClass]="{ active: recoverType === 'sms' }"
          >
            <div class="error-message" *ngIf="phoneField.errors?.pattern">
              <span>Mobil tlf. skal udfyldes!</span>
            </div>

            <input
              class="form-control"
              id="phone"
              placeholder="Indtast telefonnummer"
              name="phone"
              type="phone"
              (keyup)="handleInput($event)"
              value="phone"
              type="number"
              pattern="[0-9+-]{6,18}"
              [(ngModel)]="data.phone.value"
              #phoneField="ngModel"
            />
          </div>
        </div>
        <div class="button-wrapper">
          <button
            class="bttn bttn__fill"
            *ngIf="recoverType === 'email'"
            [disabled]="!emailFormValid"
          >
            Send
          </button>
          <button
            class="bttn bttn__fill"
            *ngIf="recoverType === 'sms'"
            [disabled]="!smsFormValid"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
