<div class="body mob-padding">
  <h3 class="section-title">Vælg kropsdel</h3>
  <div class="steps">
    <app-booking-steps [activeStep]="active_step"></app-booking-steps>
  </div>

  <div id="loading" *ngIf="loading">
    <div class="app-loading static">
      <svg class="spinner" viewBox="25 25 50 50">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        ></circle>
      </svg>
    </div>
  </div>

  <div class="box" *ngIf="noTherapists">
    Det er desværre ikke muligt at booke en tid online for det aktuelle valg
    Kontakt klinikken pr. mail eller tlf. hvis du ønsker at booke en tid.
    {{ selectedClinic[0]['email'] }} {{ selectedClinic[0]['phone'] }}
  </div>

  <div class="body-container" *ngIf="!loading">
    <div class="description box">
      <p>
        Du skal nu angive den kropsdel hvor du oplever størst gene.
      </p>
    </div>
    <div class="body-parts">
      <div id="body-block">
        <div class="body-part-chooser">
          <div class="parts-container">
            <div *ngFor="let bodyPart of bodyParts">
              <div class="part" *ngIf="bodyPart.isSingle">
                <div class="checkbox-wrapper">
                  <input
                    id="{{ bodyPart._id }}"
                    type="checkbox"
                    (change)="markBodyParts($event)"
                    [checked]="toggledPart.indexOf(bodyPart.index) !== -1"
                    value="{{ bodyPart.index }}"
                  />
                  <label
                    for="{{ bodyPart._id }}"
                    id="label_{{ bodyPart._id }}"
                  ></label>
                  <span>{{ bodyPart.name }}</span>
                </div>
              </div>

              <div class="part" *ngIf="!bodyPart.isSingle">
                <div class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="{{ bodyPart._id }}"
                    (change)="markBodyParts($event)"
                    [checked]="toggledPart.indexOf(bodyPart.index) !== -1"
                    value="{{ bodyPart.index }}"
                  />
                  <label
                    for="{{ bodyPart._id }}"
                    id="label_{{ bodyPart._id }}"
                  ></label>
                  <span>{{ bodyPart.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-buttons-wrapper" *ngIf="!loading" id="bottom-navigation">
    <div class="buttons">
      <button class="bttn" (click)="goBack()">
        <span>Tilbage</span>
      </button>

      <button
        class="bttn filled"
        (click)="nextStep()"
        *ngIf="toggledPart && toggledPart.length > 0"
      >
        <span>Næste</span>
      </button>
    </div>
  </div>
</div>
