<div class="expert mob-padding" *ngIf="availableTherapists.length > 0">
  <h3 *ngIf="!headerText" class="section-title">Skal du bruge en klinisk ekspert?</h3>
  <h3 *ngIf="headerText" class="section-title">{{ headerText }}</h3>
  <div class="steps">
    <app-booking-steps [activeStep]="active_step"></app-booking-steps>
  </div>

  <div class="box" *ngIf="availableTherapists.length === 0">
    Det er desværre ikke muligt at booke en tid online for det aktuelle valg
    Kontakt klinikken pr. mail eller tlf. hvis du ønsker at booke en tid.
    {{ selectedClinic[0]['email'] }} {{ selectedClinic[0]['phone'] }}
  </div>

  <div class="expert-container">
    <div class="left" *ngIf="availableTherapists.length > 0">
      <h4 *ngIf="!subHeaderText">Skal du bruge en klinisk ekspert?</h4>
      <h4 *ngIf="subHeaderText">{{ subHeaderText }}</h4>
      <p *ngIf="!contentText">
        Vi anbefaler en tid hos en klinisk ekspert, hvis du har et kompliceret eller langvarigt problem.
      </p>
      <p *ngIf="contentText">{{ contentText }}</p>

      <div class="buttons">
        <button
          class="bttn first"
          (click)="enableExpert($event)"
          [ngClass]="{ filled: clinicExpert && clinicExpert !== undefined }"
        >
          <span>Ja Tak</span>
        </button>

        <button
          class="bttn"
          (click)="disableExpert($event)"
          [ngClass]="{ filled: !clinicExpert && clinicExpert !== undefined }"
        >
          <span>Nej Tak</span>
        </button>
      </div>
    </div>
    <div class="right" *ngIf="availableTherapists.length > 0">
      <div class="right-column specify-column" *ngIf="clinicExpert">
        <div id="search-specialist" *ngIf="availableTherapists.length > 0">
          <div class="search-specialist-container disablable-section">
            <div>
              <h4>Vælg en behandler</h4>
            <div
              id="chosen-specialists" class="dropdown found" id="search-results-wrapper"
              *ngIf="showChosenResults"
              [ngClass]="{ chosen: chosenTherapists.length > 0 }"
              [ngClass]="{ found: availableTherapists.length > 0 }"
              >
            <span
            class="close hidden"
            (click)="removeTherapist(chosenTherapists.id)"
            >
            <img src="assets/icons/delete.svg" />
            </span>
              <div
                data-id="{{ therapist.id }}"
                class="search-result"
                *ngFor="let therapist of availableTherapists"
              >
                <div
                *ngIf="therapist.id === chosenTherapists[0].id"
                 class="chosen-specialist adevo-chosen-specialist">
                  <div
                    class="picture"
                    [ngStyle]="{
                      'background-image': 'url(' + therapist.picture + ')'
                    }"
                  ></div>
                  <div class="specialist-info">
                    <h5>{{ therapist.name}}</h5>
                  </div>
               </div>
                <div
                *ngIf="therapist.id !== chosenTherapists[0].id"
                class="found adevo-found-specialist found-specialist"
                (mousedown)="chooseTherapist(therapist)"
                (click)="removeFoundResults()"
              >
                <div
                  class="picture"
                  [ngStyle]="{
                    'background-image': 'url(' + therapist.picture + ')'
                  }"
                ></div>
                <div class="specialist-info">
                  <h5>{{ therapist.name }}</h5>
                </div>
                <span
                class="close"
                (click)="removeTherapist(chosenTherapist.id)"
              >
                <img src="assets/icons/delete.svg" />
                </span>
              </div>
              </div>
            </div>

            <div
              id="search-results-wrapper"
              *ngIf="showFoundResults"
              class="dropdown"
              [ngClass]="{ found: availableTherapists.length > 0 }"
            >
              <div
                data-id="{{ foundTherapist.id }}"
                class="search-result adevo-found-specialist"
                *ngFor="let foundTherapist of availableTherapists"
              >
                <div
                  class="found-specialist"
                  (mousedown)="chooseTherapist(foundTherapist)"
                  (click)="removeFoundResults()"
                >
                  <div
                    class="picture"
                    [ngStyle]="{
                      'background-image': 'url(' + foundTherapist.picture + ')'
                    }"
                  ></div>
                  <div class="specialist-info">
                    <h5>{{ foundTherapist.name }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-buttons-wrapper" id="bottom-navigation">
    <div class="buttons">
      <button class="bttn" (click)="goBack()">
        <span>Tilbage</span>
      </button>

      <button
        class="bttn filled"
        (click)="navigateToService()"
        *ngIf="chosenTherapists.length > 0 && availableTherapists.length > 0"
      >
        <span>Næste</span>
      </button>
    </div>
  </div>
</div>
