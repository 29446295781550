<div class="signup-container">
  <div class="signup-form-container">
    <div class="form-wrapper">
      <div class="stage-title">
        <h1>Cookie, Data og Privatlivspolitik</h1>
      </div>
      <ol>
        <li>
          <p><b>FysioDanmark online booking - www.fbooking.dk</b></p>
          <p>
            www.fbooking.dk er en online
            booking for patienter, der skal reservere tid på FysioDanmarks
            klinikker. Det er den enkelte klinik, som er dataansvarlig.
          </p>
        </li>
        <li>
          <p><b>www.fbooking.dk gør brug af cookies</b></p>
          <p>
            En cookie er en lille tekstfil, der lagres på din computer el.
            tilsvarende for at kunne genkende den. Der er ingen personlige
            oplysninger gemt i vores cookies.
          </p>
        </li>
        <li>
          <p><b>Formål med cookies på vores website</b></p>
          <p>
            Vi benytter cookies så vi kan huske dine indstillinger og huske om
            du er logget ind. Sitet anvender ikke tredjepartsservices, som
            benytter sig af cookies.
          </p>
        </li>
        <li>
          <p><b>Sådan sletter eller blokerer du for cookies</b></p>
          <p>
            Se vejledning
            <a href="https://minecookies.org/cookiehandtering/" target="_blank"
              >https://minecookies.org/cookiehandtering/</a
            >.
          </p>
        </li>
        <li>
          <p><b>Brug af personoplysninger</b></p>
          <p>
            Personoplysninger afgives aldrig til tredjepart, medmindre du selv
            udtrykkeligt giver tilsagn hertil. Der indsamles kun oplysninger som
            er nødvendige for sitets funktionalitet.
          </p>
        </li>
        <li>
          <p><b>Beskyttelse af personoplysninger</b></p>
          <p>
            Ifølge persondataloven skal dine personlige oplysninger opbevares sikkert og fortroligt. Vi opbevarer dine personlige oplysninger på computere med begrænset adgang. 
            Vores sikkerhedsforanstaltninger kontrolleres løbende for at afgøre, om vores brugeroplysninger håndteres forsvarligt, og under stadig hensyntagen til dine rettigheder som bruger. 
            Derudover er vi underlagt tilsyn fra Erhvervsstyrelsen. Den hurtige udvikling af internettet betyder, at ændringer i vores behandling af personoplysninger kan blive nødvendige. 
            Vi forbeholder os derfor ret til at opdatere og ændre nærværende retningslinjer for behandling af personoplysninger. 
            I tilfælde af væsentlige ændringer giver vi dig besked i form af en synlig meddelelse på vores website. 
          </p>
        </li>
      </ol>
    </div>
  </div>
</div>
