<div class="login-container">
  <div class="login-container__top">
    <div class="login-container__top--form">
      <h3>Nulstil adgangskode</h3>
      <app-info-messages
        #infoMessage
        [notification]="notification"
      ></app-info-messages>
      <form
        id="login-form"
        autocomplete="off"
        #myForm="ngForm"
        (ngSubmit)="sendResetPassword()"
      >
        <div class="form-group">
          <div class="login-input">
            <input
              type="password"
              class="form-control"
              id="password1"
              placeholder="Ny adgangskode"
              #pswd1
              (keyup)="(0)"
              name="password1"
              required
              ngModel
            />
          </div>
          <div class="login-input">
            <input
              type="password"
              class="form-control"
              id="password2"
              placeholder="Gentag ny adgangskode"
              #pswd2
              (keyup)="(0)"
              name="password2"
              required
              ngModel
            />
          </div>
        </div>
        <div class="button-wrapper">
          <button
            class="bttn bttn__fill"
            [disabled]="pswd1.value !== pswd2.value"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
