<div class="clinics-container mob-padding">
  <h3 class="section-title">Vælg ønskede klinik</h3>
  <div class="steps">
    <app-booking-steps [activeStep]="active_step"></app-booking-steps>
  </div>
  <div class="info-container" *ngIf="aalborgFlow !== undefined && aalborgFlow">
    <div class="info">
      <img src="assets/icons/exclamation-mark-green.svg" alt="Info" />
      <div>
        <p><b>VIGTIGT:</b></p>
        <p>
          Når du møder op til behandling, er det vigtigt, at du ikke bærer på
          symptomer på COVID-19 eller har mistanke om smitte.
        </p>
      </div>
    </div>
  </div>
  <div id="map-container">
    <div id="loading">
      <div class="app-loading map-loading">
        <div class="logo"></div>
        <svg class="spinner" viewBox="25 25 50 50">
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </div>
    <div class="map-wrapper">
      <div id="map"></div>
      <div id="clinic-overlay" *ngIf="isSelectedClinicOverlay">
        <div class="overlay-wrapper">
          <div class="overlay-header">
            <span class="main-info"> {{ selectedClinicOverlay.name }} </span>
            <span
              class="close"
              (click)="isSelectedClinicOverlay = !isSelectedClinicOverlay"
            ></span>
          </div>
          <div class="overlay-body">
            <span> {{ selectedClinicOverlay.address }} </span>
            <span> {{ selectedClinicOverlay.region }} </span>
            <span> {{ selectedClinicOverlay.post_code }} </span>
            <a href="{{ selectedClinicOverlay.website }}" target="_blank">
              {{ selectedClinicOverlay.website }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="map-filters">
      <div id="helper-region">
        <div
          class="choose-clinic"
          [ngClass]="{ 'disabled-element': isExternal }"
          *ngIf="clinicsToShow"
        >
          <div class="area-clinics">
            <div class="search-container">
              <input
                class="form-control input-text"
                placeholder="Søg efter navn eller postnummer..."
                (keyup)="searchClinics($event)"
              />
              <img src="{{ search }}" />
            </div>

            <div class="error" *ngIf="clinicsToShow.length === 0">
              Vi kan ikke finde nogle klinikker der matcher din søgning.
            </div>

            <div class="clinic-container">
              <ng-container *ngIf="selectedClinics.length > 0; else elseBlock">
                <div
                  class="clinic-container__box"
                  *ngFor="let areaClinic of clinicsToShow"
                  (click)="fetchClinics(areaClinic._id)"
                  data-id="{{ areaClinic._id }}"
                >
                  <div class="clinic-container__box--left">
                    <img src="../../../assets/icons/pin.svg" alt="Location" />
                  </div>
                  <div class="clinic-container__box--center">
                    <p>{{ areaClinic.name }}</p>
                  </div>
                  <div class="clinic-container__box--right">
                    <p *ngIf="areaClinic.address">{{ areaClinic.address }}</p>
                    <p>{{ areaClinic.post_code }} {{ areaClinic.city }}</p>
                  </div>
                </div>
              </ng-container>
              <ng-template #elseBlock>
                <div
                  class="clinic-container__box"
                  *ngFor="let areaClinic of clinicsToShow"
                  (click)="fetchClinics(areaClinic._id)"
                  data-id="{{ areaClinic._id }}"
                >
                  <div class="clinic-container__box--left">
                    <img src="../../../assets/icons/pin.svg" alt="Location" />
                  </div>
                  <div class="clinic-container__box--center">
                    <p *ngIf="areaClinic.name">{{ areaClinic.name }}</p>
                    <div class="clinic-container__box--center__desc">
                      <span *ngIf="areaClinic.address">{{
                        areaClinic.address
                      }}</span>
                      <span
                        >{{ areaClinic.post_code }} {{ areaClinic.city }}</span
                      >
                    </div>
                  </div>
                  <div class="clinic-container__box--right">
                    <p *ngIf="areaClinic.address">{{ areaClinic.address }}</p>
                    <p>{{ areaClinic.post_code }} {{ areaClinic.city }}</p>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          class="info-container"
          *ngIf="aalborgFlow !== undefined && aalborgFlow"
        >
          <div class="info">
            <img src="assets/icons/exclamation-mark-green.svg" alt="Info" />
            <div>
              <p>
                Behandling i FysioDanmark Aalborg C eller i FysioDanmark
                Hasseris kan kun ske ved visitation fra en fysioterapeut i
                Inhouse - klinikken eller i akutte tilfælde ved telefonkontakt
                på <a href="tel:99204060">99204060.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
