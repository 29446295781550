<div class="navigation-container">
  <div class="navigation mob-padding">
    <div class="navigation__left">
      <a (click)="toWelcome()">
        <img src="../../../assets/icons/grid.svg" alt="Home" />
      </a>
    </div>
    <div class="navigation__center" *ngIf="displayNav == true && is_logged_in">
      <a class="navigation__center--box" (click)="bookNew()">
        <img src="../../../assets/icons/book.svg" alt="" />
        <p>Book Tid</p>
      </a>
      <a
        class="navigation__center--box all-my-bookings"
        (click)="myBookings()"
        routerLinkActive="active"
      >
        <img src="../../../assets/icons/calendar.svg" alt="" />
        <p>Mine bookinger</p>
      </a>
      <a
        class="navigation__center--box"
        (click)="toProfile()"
        routerLinkActive="active"
      >
        <img src="../../../assets/icons/user.svg" alt="" />
        <p>Mine oplysninger</p>
      </a>
    </div>
    <div
      class="navigation__right"
      *ngIf="displayNav == true && is_logged_in"
    ></div>
  </div>
</div>
