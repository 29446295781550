<div class="service mob-padding">
  <h3 class="section-title">Vælg konsultationstype</h3>
  <div class="steps">
    <app-booking-steps [activeStep]="active_step"></app-booking-steps>
  </div>

  <div class="bv-info" *ngIf="firstTime && first_booking_text">
    <div class="info">
      <img src="assets/icons/exclamation-mark-green.svg" alt="Info" />
      <p>{{ first_booking_text }}</p>
    </div>
  </div>

  <div
    class="bv-info"
    *ngIf="
      isFysio &&
      referral === 1 &&
      selectedClinic[0].referral_info &&
      showReferral
    "
  >
    <div class="info">
      <img src="assets/icons/exclamation-mark-green.svg" alt="Info" />
      <p>{{ selectedClinic[0].referral_info }}</p>
    </div>
  </div>

  <div
    class="box"
    *ngIf="
      (!services && services !== undefined) ||
      (haveTherapists !== undefined && !haveTherapists)
    "
  >
    Det er desværre ikke muligt at booke en tid online for det aktuelle valg
    Kontakt klinikken pr. mail eller tlf. hvis du ønsker at booke en tid.
    {{ selectedClinic[0]['email'] }} {{ selectedClinic[0]['phone'] }}
  </div>

  <div class="service-container">
    <div
      class="left"
      *ngIf="
        services &&
        services !== undefined &&
        haveTherapists !== undefined &&
        haveTherapists
      "
    >
      <div id="choose-treatment">
        <div class="select-wrapper" *ngIf="fisiqClinics">
          <ng-container *ngFor="let service_item of filteredServices">
            <div class="service-wrapper">
              <ng-container>
                <ng-container>
                  <div class="item-fisiq-wrapper">
                    <div
                      class="item-fisiq"
                      [ngClass]="{
                        'item-fisiq-set':
                          dropdownSelection !== undefined &&
                          dropdownSelection === service_item.service_name
                      }"
                      (click)="setDropdownSelection($event, service_item)"
                    >
                      <span>{{ service_item.service_name }}</span>
                    </div>
                    <div class="item-fisiq-tip" *ngIf="service_item.info">
                      <img
                        src="{{ questionMark }}"
                        (click)="openMessage($event)"
                      />
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div class="info-message-container" *ngIf="service_item.info">
                <span class="item-fisiq-tip-message">
                  <div class="info">
                    <img src="{{ questionMark }}" /> Information
                  </div>
                  <div class="close" (click)="closeMessage($event)">
                    <img src="assets/icons/close-green.svg" />
                  </div>
                  <div class="content">
                    {{ service_item.info }}
                  </div>
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-buttons-wrapper" id="bottom-navigation">
    <div class="buttons">
      <button class="bttn" (click)="goBack()">
        <span>Tilbage</span>
      </button>

      <button
        class="bttn filled"
        (click)="bookVisit()"
        *ngIf="
          selectedService &&
          services &&
          services !== undefined &&
          haveTherapists !== undefined &&
          haveTherapists
        "
      >
        <span>Næste</span>
      </button>
    </div>
  </div>
</div>
