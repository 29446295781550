<div class="offer mob-padding">
  <h3 class="section-title">Vælg ønskede behandling</h3>
  <div class="steps">
    <app-booking-steps [activeStep]="active_step"></app-booking-steps>
  </div>

  <div id="loading" *ngIf="loading">
    <div class="app-loading static">
      <svg class="spinner" viewBox="25 25 50 50">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>

  <div
    class="box"
    *ngIf="
      (clinicServices && clinicServices.length === 0) ||
      (clinicServices.length === 1 && clinicServices[0] === 'Danica Ydelser') ||
      noTherapists
    "
  >
    Det er desværre ikke muligt at booke en tid online for det aktuelle valg
    Kontakt klinikken pr. mail eller tlf. hvis du ønsker at booke en tid.
    {{ selectedClinic[0]['email'] }} {{ selectedClinic[0]['phone'] }}
  </div>
  <div class="offer__buttons" *ngIf="!loading">
    <div
      *ngFor="let clinicService of clinicServices"
      class="offer__buttons--item"
      (click)="bookNew((service = clinicService.group_code))"
    >
      <img
        *ngIf="clinicService.group_icon"
        src="{{
          biURL + '/img/icons/clinic_group_icons/' + clinicService.group_icon
        }}"
        alt="Book tid"
      />
      <p>{{ clinicService.group_name }}</p>
    </div>
  </div>

  <div
    class="nav-buttons-wrapper"
    id="bottom-navigation"
    *ngIf="singleClinic === 'false' && !loading"
  >
    <div class="buttons">
      <button class="bttn" (click)="goBack()">
        <span>Tilbage</span>
      </button>
    </div>
  </div>
</div>
