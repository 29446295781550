<div id="loading" *ngIf="loading">
  <div class="app-loading profile-loading">
    <div class="logo"></div>
    <svg class="spinner" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</div>

<div class="booking-success mob-padding">
  <h3 class="section-title">
    Tak - din booking er nu gennemført
    <p>
      Du modtager en bekræftelse på mail samt en SMS-reminder 24 timer inden din
      aftale
    </p>
  </h3>
  <app-booking-steps [activeStep]="active_step"></app-booking-steps>
  <div class="booking-success__container" *ngIf="isBookingSummary">
    <div class="booking-success__left">
      <div class="booking-success__left--top">
        <h4>Dine bookinginformationer</h4>
        <div class="booking-success__left--top-icons">
          <img
            src="../../../assets/icons/print.svg"
            (click)="export(currentBooking[0])"
            alt=""
          />
          <img
            src="../../../assets/icons/delete.svg"
            (click)="openDeletePopup(currentBooking[0])"
            alt=""
            *ngIf="showDelete"
          />
        </div>
      </div>

      <div class="booking-success__left--content">
        <div class="booking-success__left--content-left">
          <div class="booking-success__left--content__box">
            <h5>Personlige oplysninger</h5>
            <p *ngIf="currentUser.data.firstName">
              {{ currentUser.data.firstName }} {{ currentUser.data.lastName }}
            </p>
            <p *ngIf="currentUser.data.address">
              {{ currentUser.data.address }}
            </p>
            <p *ngIf="currentUser.data.postalNr">
              {{ currentUser.data.postalNr }}
              <span *ngIf="currentUser.data.city">{{
                currentUser.data.city
              }}</span>
            </p>
            <p *ngIf="currentUser.data.CPRNumber">
              CPR: {{ currentUser.data.CPRNumber }}
            </p>
          </div>
          <div class="booking-success__left--content__box">
            <h5>Valg af klinik</h5>
            <p>{{ currentBooking[0].clinicData.name }}</p>
            <p>{{ currentBooking[0].clinicData.address }}</p>
            <p>
              {{ currentBooking[0].clinicData.post_code }}
              {{ currentBooking[0].clinicData.city }}
            </p>
          </div>
        </div>
        <div class="booking-success__left--content-right">
          <div class="booking-success__left--content__box">
            <h5>
              {{
                type === 'aalborg'
                  ? 'Konsultationstype'
                  : 'Bookinginformationer'
              }}
            </h5>
            <p>
              {{
                type === 'aalborg'
                  ? 'Sundhedsordning Ældre- og Handicapforvaltningen'
                  : currentBooking[0].comment.behandling
              }}
            </p>
          </div>
          <div class="booking-success__left--content__box">
            <h5>Valg af fysioterapeut</h5>
            <p>{{ currentBooking[0].therapistData.name }}</p>
          </div>
          <div class="booking-success__left--content__box">
            <h5>Dato og tidspunkt</h5>
            <p>
              {{ currentBooking[0].date.split(' ')[0] }}.
              {{ currentBooking[0].date.split(' ')[1].toLowerCase() }}, kl.
              {{ currentBooking[0].from.substring(11, 16) }} -
              {{ currentBooking[0].to.substring(11, 16) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="booking-success__right">
      <h4>Book flere tider</h4>
      <p *ngIf="type !== 'aalborg'">Det er en god ide at booke flere tider</p>
      <button
        class="bttn"
        (click)="bookOfferedVisit()"
        *ngIf="currentBooking[0].therapistData"
      >
        Book endnu en tid hos {{ currentBooking[0].therapistData.name }}
      </button>
      <button class="bttn" (click)="reload()">Luk</button>
    </div>
  </div>
</div>

<div class="popup-container" *ngIf="show">
  <div class="popup">
    <img src="{{ delete }}" class="close-popup" (click)="closeDeletePopup()" />
    <div class="popup-title">Vil du slette denne reservation?</div>
    <div class="button-container">
      <button
        class="bttn accept-remove"
        (click)="deleteBooking(currentBooking[0])"
      >
        Ja
      </button>
      <button class="bttn filled" (click)="closeDeletePopup()">Nej</button>
    </div>
  </div>
</div>