<div class="profile mob-padding">
  <h3 class="section-title">Mine Oplysninger</h3>
  <app-info-messages
    #infoMessage
    [notification]="notification"
  ></app-info-messages>
  <div class="profile__container">
    <div class="box" *ngIf="noEmail || noCpr">
      Vi mangler nogle oplysninger for at du kan fortsætte til bookingsystemet. 
      Udfyld de påkrævede felter og tryk 'Gem oplysninger' for at fortsætte.
    </div>

    <form id="signup-form" #signupForm="ngForm" autocomplete="off">
      <div class="">
        <div class="signup-form-wrapper profile__container--items">
          <ng-container *ngFor="let formElement of formElements; let i = index">
            <div
              class="signup-input"
              [ngClass]="{
                hidden:
                  formElement.key === 'username' ||
                  (aalborgFlow &&
                    (formElement.key === 'danmNr' ||
                      formElement.key === 'insuranceCompany'))
              }"
              [ngSwitch]="formElement.controlType"
              [ngStyle]="{ 'z-index': formElements.length - i }"
            >
              <label
                class="form-label"
                [ngClass]="{ required: formElement.required }"
                >{{ formElement.label }}</label
              >
              <span *ngSwitchCase="'dropdown'">
                <span
                  class="ui primary dropdown button form-control select-dropdown"
                  suiDropdown
                >
                  <span
                    class="text select-dropdown__text"
                    [id]="formElement.key"
                    required
                  >
                    <ng-container *ngFor="let selected of dropdownSelection">
                      <ng-container *ngIf="selected.key == formElement.key">
                        {{ selected.value }}
                      </ng-container>
                    </ng-container>
                    <span *ngIf="helperDrpdwKeys.indexOf(formElement.key) == -1"
                      >Vælg venligst</span
                    >
                  </span>
                  <i class="dropdown icon"></i>
                  <span class="adevo-menu" class="menu" suiDropdownMenu>
                    <ng-container *ngFor="let value of formElement.vals">
                      <span
                        class="item"
                        (click)="setDropdownSelection(formElement.key, value)"
                        >{{ value.value }}</span
                      >
                    </ng-container>
                  </span>
                </span>
              </span>

              <span class="input-container" *ngSwitchDefault>
                <input
                  [type]="formElement.controlType"
                  [ngClass]="{
                    disabled: (clinics && formElement.key === 'clinicId') || (formElement.key === 'cprNr' && !noCpr)
                  }"
                  class="form-control input-text"
                  [id]="formElement.key"
                  [name]="formElement.key"
                  [required]="formElement.required"
                  [(ngModel)]="formElement.value"
                  [pattern]="formElement.pattern"
                  [customValidator]="formElement"
                  (keyup)="onKey($event, formElement.key)"
                />
              </span>

              <span
                class="error-message"
                [ngClass]="
                  signupForm.controls[formElement.key]?.errors
                    ? 'INVALID'
                    : 'VALID'
                "
              >
                <span
                  *ngIf="
                    signupForm.controls[formElement.key]?.errors?.required &&
                    signupForm.controls[formElement.key]?.touched
                  "
                  >{{ formElement.errors.pattern }}</span
                >
                <span
                  *ngIf="signupForm.controls[formElement.key]?.errors?.pattern"
                  >{{ formElement.errors.pattern }}</span
                >
                <span
                  *ngIf="signupForm.controls[formElement.key]?.errors?.custom"
                  >{{ formElement.errors.custom }}</span
                >
              </span>
            </div>
          </ng-container>

          <div class="change-password-button" (click)="showPasswordFields()">
            Ændre kodeord
            <img
              [ngClass]="{ active: showPasswords }"
              src="../../../assets/icons/arrow-r.svg"
              alt=""
            />
          </div>

          <div class="signup-input">
            <div *ngIf="showPasswords">
              <div>
                <label for="password" class="form-label">Kodeord</label>

                <input
                  type="password"
                  class="form-control input-text"
                  id="password"
                  name="password"
                  required="false"
                  value=""
                  (keyup)="onKey($event, 'password')"
                />
              </div>

              <div>
                <label for="password" class="form-label">Gentag kodeord</label>

                <input
                  type="password"
                  class="form-control input-text"
                  id="repeatPassword"
                  name="repeatPassword"
                  required="false"
                  value=""
                  (keyup)="onKey($event, 'repeatPassword')"
                />
              </div>
            </div>
          </div>

          <div class="div">
            <button
              class="bttn bttn__fill bttn__profile"
              [disabled]="!signupForm.form.valid"
              (click)="updateProfile()"
            >
              Gem oplysninger
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
