<div class="book mob-padding">
    <h3 class="section-title">Vælg tidspunkt og book</h3>
    <div class="steps">
        <app-booking-steps [activeStep]="active_step"></app-booking-steps>
    </div>

    <div class="box" *ngIf="(haveTherapists !== undefined && !haveTherapists) || wrong">
		Det er desværre ikke muligt at booke en tid online for det aktuelle valg
		Kontakt klinikken pr. mail eller tlf.  hvis du ønsker at booke en tid.
		{{ selectedClinic[0]['email'] }} {{ selectedClinic[0]['phone'] }}
	</div>

    <div class="book-container">
        <div class="left" *ngIf="selectedTherapists.length > 0 && !wrong">
            <div id="chosen-specialists"
                *ngIf="fisiqClinics"
                [ngClass]="{'chosen': selectedTherapists.length > 0 }">
            <div class="title">
                <h4>Valg af behandler</h4>
            </div>
            <div class="choose-specialists">
                <div
                    data-id="{{therapist.id}}"
                    class="chosen-specialist-wrapper adevo-chosen-specialist"
                    [ngClass]="{'selected-therapist': selectedTherapist == therapist.id || therapistsWithTimes.includes(therapist.id), 'unselect': selectedTherapists.length === 1}"
                    (click)="chooseTime(therapist)"
                    *ngFor="let therapist of selectedTherapists">
                    <div class="chosen-specialist">
                        <div class="picture" [ngStyle]="{'background-image': 'url(' + therapist.picture + ')'}"></div>
                        <div class="specialist-info">
                            <h5>{{ therapist.name }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="center" *ngIf="selectedTherapists.length > 0 && !wrong">
            <div id="choose-date">
                <div class="title">
                    <h4>Vælg dato</h4>
                    <button class="bttn bttn-outline"
                            (click)="clearAll()"
                            *ngIf="showClearAll">NULSTIL VALG</button>
                </div>
                <div class="date-selector-wrapper adevo-datepicker">
                    <my-date-picker [options]="myDatePickerOptions"
                                    (dateChanged)="onDateChanged($event)"
                                    *ngIf="!dateLoading"></my-date-picker>
                </div>
            </div>
        </div>
        <div class="right" *ngIf="selectedTherapists.length > 0 && !wrong">
            <div id="choose-time">
                <div class="title">
                    <h4>Vælg tidspunkt</h4>
                </div>
                <div id="available-times-wrapper">

                    <div class="available-times" *ngFor="let availableTime of availableTimes">
                        <div
                            data-id="{{availableTime._id}}"
                            class="time adevo-time"
                            [ngClass]="{'time-set': timeSelected._id == availableTime._id}"
                            (click)="selectTime(availableTime)">
                            <span>Kl. {{ availableTime.startTime }}</span>
                        </div>
                    </div>

                    <div *ngIf="loading">
                        <img src="{{ loadingIcon }}" />
                    </div>

                    <div class="description-multiple" *ngIf="showDescription && !loading && selectedTherapists.length > 1 && selectedTherapist.length === 0">
                        <p class="bold">Booking af tid hos specifik fysioterapeut</p>
                        <p>Vælg ønskede fysioterapeut + dato og du vil få vist alle ledige tider </p>
                        <p class="bold second">Booking af tid uden valg af specifik fysioterapeut </p>
                        <p>Du kan sagtens booke tid uden valg af specifik fysioterapeut - vælg dato og du vil få vist alle ledige tider.</p>
                    </div>

                    <div class="description-single" *ngIf="showDescription && !loading && availableTherapists.length > 0 && selectedTherapist.length > 0">
                        <p class="bold">Booking af tid hos {{ availableTherapists[0].name }}</p>
                        <p>Vælg dato og du vil få vist alle ledige tider</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="nav-buttons-wrapper"
         id="bottom-navigation">
        <div class="buttons">
            <button class="bttn" (click)="goBack()">
                <span>Tilbage</span>
            </button>

			<button class="bttn filled"
                    (click)="bookVisit()"
                    *ngIf="selectedId">
                <span>GODKEND</span>
            </button>
        </div>
	</div>
</div>
